import React from 'react';
import SEO from '../components/seo';
import { Header } from '../components/header';
import css from './resource.module.css';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { Link } from 'gatsby';
import { BackIcon } from '../components/logos';
import { Footer } from '../components/footer';

export default ({ pageContext: { resource, field, subfield, resourceType } }) => {
    return (
        <div className={css.container}>
            <div>
                <SEO title={`${resource.name} by ${resource.authors} - Free ${subfield.name} Resource`} />
                <Header />
                <div className={css.wrapper}>
                    <div className={css.linkBackContainer}>
                        <Link to={`/${field.slug}/${subfield.slug}`} className={css.linkBack}>{<BackIcon className={css.backIcon} />}{field.name} / {subfield.name}</Link>
                    </div>
                    <h2 className={css.header}>{resource.name}</h2>
                    <div className={css.authors}>{resource.authors}</div>
                    <ul className={css.links}>{(resource.urls || []).map(([name, url]) => 
                        <li><OutboundLink target="_blank" href={url} className={css.link}>{name}</OutboundLink></li>)}
                    </ul>
                    <div className={css.info}>
                        <h3 className={css.infoHeader}>Resource Type:</h3>
                        <div>{resourceType}</div>
                        {resource.tags &&
                            <>
                                <h3 className={css.infoHeader}>Tags:</h3>
                                <ul className={css.tags}>{(resource.tags || []).map(tag => <li>{tag}</li>)}</ul>
                            </>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
